
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";

interface ICountyActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "countyActivitiesView",

  mixins: [ActivitiesMixin],
  data(): ICountyActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
          ADD_BULK_COUNTY_SUCCESS,
          ADD_BULK_COUNTY_FAILURE,
          DELETE_BULK_COUNTY_FAILURE,
          DELETE_BULK_COUNTY_SUCCESS,
          DELETE_COUNTY_FAILURE,
          DELETE_COUNTY_SUCCESS,
          ADD_COUNTY_FAILURE,
          ADD_COUNTY_SUCCESS,
          PROHIBIT_BINDING_COUNTY_FAILURE,
          PROHIBIT_BINDING_COUNTY_SUCCESS,
          TOGGLE_PROHIBIT_BINDING_ALL_COUNTY_FAILURE,
          TOGGLE_PROHIBIT_BINDING_ALL_COUNTY_SUCCESS,
          UPDATE_COUNTY_FAILURE,
          UPDATE_COUNTY_SUCCESS,
          DELETE_BULK_COUNTY_SUCCESS,
          DELETE_BULK_COUNTY_FAILURE,
          PROHIBIT_BINDING_COUNTY_SUCCESS,
          PROHIBIT_BINDING_COUNTY_FAILURE
          `.replace(/\s/g, ""),
        activityType: "CountyActivity"
      };
    }
  }
});
